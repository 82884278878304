<template>
  <SimseiApp>
    <div id="login-page-content">
      <div id="form-content">
        <router-view></router-view>
      </div>
    </div>
  </SimseiApp>
</template>

<script>

export default {
  name: 'LoginApp'
}
</script>

<style lang="scss">
@import "@/assets/scss/_mixins.scss";

#form-content {
  @include center;

  min-height: 100vh;
}

#login {
  font-family: "Open Sans", sans-serif;
  color: $neutral-typography-dark;
  letter-spacing: 0.2px;
}
</style>
